.orderHeader .orderBtn {
  background: #3c4b63;
  color: #ffffff;
  border: 1px solid;
  height: 40px;
  line-height: 38px;
  padding: 0 20px;
  text-align: center;
  margin-right: 20px;
  width: 200px;
  border-radius: 5px;
  font-family: "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
}

.orderHeader .createOrdBtn {
  background: #3c4b63;
  color: #ffffff;
  border: 1px solid;
  height: 40px;
  line-height: 38px;
  padding: 0 20px;
  text-align: center;
  /* margin-right: 10px; */
  position: absolute;
  right: 10px;
  border-radius: 5px;
  font-family: "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
}

.orderHeader ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
  position: relative;
}

.orderHeader li {
  display: inline;
  /* padding: 15px; */
}
.orderHeader a {
  color: #ffffff;
  text-decoration: none;
  float: left;
}
.orderHeader a:hover {
  background-color: #ced2d8;
  cursor: pointer;
}

ol.progtrckr {
  list-style-type: none;
  padding: 0;
}

ol.progtrckr li {
  display: inline-block;
  text-align: center;
  line-height: 4.5rem;
  cursor: pointer;
}

ol.progtrckr li span {
  padding: 0 1.5rem;
}

@media (max-width: 650px) {
  .progtrckr li span {
    display: none;
  }
}
.progtrckr em {
  display: none;
  font-weight: 700;
  padding-left: 1rem;
}

@media (max-width: 650px) {
  .progtrckr em {
    display: inline;
  }
}

ol.progtrckr li.progtrckr-todo {
  color: silver;
  border-bottom: 4px solid silver;
}

ol.progtrckr li.progtrckr-doing {
  color: black;
  border-bottom: 4px solid #cccccc;
}

ol.progtrckr li.progtrckr-done {
  color: black;
  border-bottom: 4px solid #5cb85c;
}

ol.progtrckr li:after {
  content: "\00a0\00a0";
}

ol.progtrckr li:before {
  position: relative;
  bottom: -3.7rem;
  float: left;
  left: 50%;
}

ol.progtrckr li.progtrckr-todo:before {
  content: "\039F";
  color: silver;
  background-color: white;
  width: 1.2em;
  line-height: 1.4em;
}

ol.progtrckr li.progtrckr-todo:hover:before {
  color: #ff4500;
}

ol.progtrckr li.progtrckr-doing:before {
  content: "\2022";
  color: white;
  background-color: #cccccc;
  width: 1.2em;
  line-height: 1.2em;
  border-radius: 1.2em;
}

ol.progtrckr li.progtrckr-doing:hover:before {
  color: #ff4500;
}

ol.progtrckr li.progtrckr-done:before {
  content: "\2713";
  color: white;
  background-color: #5cb85c;
  width: 1.2em;
  line-height: 1.2em;
  border-radius: 1.2em;
}

ol.progtrckr li.progtrckr-done:hover:before {
  color: #333;
}

#orderWizard {
  margin: auto;
}

.supplierSelection {
  margin-left: 50px;
}

.finishOrderBtn {
  margin-left: 40%;
}

.countPOrder {
  position: absolute;
  top: 50%;
  margin-top: 8px;
  margin-left: 2px;
}

.qty {
  width: 10px;
}
.summary {
  text-align: center;
}
#multiselectContainerReact {
  background: white;
}
.actionIcon {
  cursor: pointer;
  width: 100px;
}

.icon-circle {
  display: inline-block;
  margin-left: 30px;
  margin-top: 35px;
  background-color: #f7b924;
  border-radius: 60px;
  box-shadow: 0px 0px 2px #888;
  padding: 15px;
}

.icon-circle1 {
  display: inline-block;
  margin-left: 30px;
  margin-top: 35px;
  background-color: #d92550;
  border-radius: 60px;
  box-shadow: 0px 0px 2px #888;
  padding: 15px;
}

.icon-circle2 {
  display: inline-block;
  margin-left: 30px;
  margin-top: 35px;
  background-color: #3ac47d;
  border-radius: 60px;
  box-shadow: 0px 0px 2px #888;
  padding: 15px;
}
.icon-size {
  width: 60px;
  height: 60px;
}
.icon-count {
  margin-left: 110px;
  font-size: 2.5rem;
  font-weight: bold;
}
.icon-count1 {
  margin-left: 110px;
  font-size: 2.5rem;
  font-weight: bold;
}
.icon-count2 {
  margin-left: 110px;
  font-size: 2.5rem;
  font-weight: bold;
  color: #3ac47d;
}
.icon-title {
  margin-left: 110px;
  /* margin-bottom: -30px; */
  margin-top: -80px;
  /* font-size: ; */
  /* font-weight: bold; */
  color: gray;
  opacity: 0.6;
}
.row-kpis {
  background: white;
  margin-bottom: 20px;
  height: 150px;
  margin-left: 1px;
  margin-right: 1px;
}
.logo-order {
  width: auto;
}
.invoiceRow {
  flex-wrap: nowrap;
}
.invoiceRow .form-control {
  width: 50%;
}
.summaryInv {
  font-weight: 600;
}
.surchargeSec {
  margin-left: 10px;
  /* margin-right: 10px; */
}
.createInv .form-control {
  font-size: small;
}

.text-center {
  text-align: center;
}

.modal_popup {
  /* display: none; */
  /* position: fixed; */
  /* top: 50%; */
  /* right: 50%; */
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  padding: 10px;
  width: 100%;
  height: 100%;
  transform: translate(-1.5%, -24%);
}

.modal_content {
  background-color: white;
  position: relative;
  width: 600px;
  margin: auto;
  padding: 19px 0px;
}

.widerCreate {
  width: 100%;
  height: 10%;
  margin-top: 15px;
}

.modal_background {
  /* background-color: white; */
  /* position: relative; */
  width: 100%;
  margin-top: 33vh;
}


.rbc-calendar { min-height: 500px; }


.react-calendar-timeline .rct-sidebar .rct-sidebar-row.rct-sidebar-row-odd {
  background:#f8fafc !important;
  /* min-height: 60px !important; */
}
.react-calendar-timeline .rct-sidebar .rct-sidebar-row.rct-sidebar-row-even{
  background: #f8fafc !important;
  /* min-height: 60px !important; */
}

.react-calendar-timeline .rct-dateHeader{
  background: #f8fafc !important;
  color:black !important;
  /* min-height: 60px !important; */
  border-left: 1px solid #bbb !important;  
  border-bottom: 2px solid #bbb !important;
}

.rct-header-root{
  background: #f8fafc !important;
  /* min-height: 60px !important; */
}

.react-calendar-timeline .rct-horizontal-lines .rct-hl-even {
  background: #fff !important; 
  /* min-height: 60px !important; */
}

.react-calendar-timeline .rct-horizontal-lines .rct-hl-odd{
  background: #fff !important;
  /* min-height: 60px !important; */
}

.react-calendar-timeline .rct-calendar-header {
  border: none !important;
}

.center {
  /* margin: auto; */
  width: 100%;
  display: flex;
  justify-content: space-between;
  /* border: 3px solid green; */
  /* padding: 10px; */
}

.flexContainer {
  display: flex;
  flex-direction: row;
}

.flexContainer > div{
  margin: 5px;
}

.flexCol{
  display: flex;
  flex-direction:column;
}

.groupHeight{
  min-height: 60px;
}

.sticky {
  position: sticky;
  top: 0;
  z-index: 100;
}

.hidden{
  display: none;
}

.contextMenu {
  position: fixed;
  background: white;
  padding: 10px;
  box-shadow: 2px 2px #999999;
  z-index: 200;
}
button:focus{
  outline: none;
}
.report-style-class{
  height: 600px;
}
.MuiTabs-root .MuiTabs-scroller.MuiTabs-fixed .PrivateTabIndicator-colorSecondary-4 {
  background-color: #3c4b64;
}
ul.pipeline-category-list {
  list-style: none;
}
.pipeline-category-list li.pipeline-category-item {
  background: #3c4b64;
  color: #fff;
  font-size: 17px;
  font-weight: 400;
  padding: 7px 10px;
  border: 1px solid #fff;
  cursor: pointer;
  text-transform: capitalize;
}

ul.pipeline-list {
  list-style: none;
}
ul.pipeline-list li.pipeline-list-item {
  background: #3c4b64;
  color: #fff;
  font-weight: 400;
  padding: 7px;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 1px;
}
.file-step {
  text-align: center;
  padding: 11px 0px;
  width: 65px;
  border-radius: 29px;
  background: #3c4b64;
  color: #fff;
  cursor: pointer;
  min-height: 63px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.step-content.hide{
  display: none;
}
.file-step.active {
  background: #131111;
}
.form-control-file {
  border: 1px solid gray;
  padding: 7px;
  width: 70%;
  display: inline-block;
  border-radius: 5px 0px 0px 5px;
  margin-top: -4px;
  color: #fff;
}

button.upload-button.btn.btn-primary {
  display: inline-block;
  height: 47px;
}
.upload-div img {
  height: 131px;
}
button.upload-button.btn.btn-primary {
  display: inline-block;
  height: 47px;
  margin-top: -5px;
  border-radius: 0px 5px 5px 0px;
}

.step-content-output {
  margin-top: 21%;
}
.step-content-output .output-button-div {
  width: 130px;
  background: #3c4b64;
  padding: 10px;
  color: #fff;
  margin: auto;
  text-align: center;
  display: inline-block;
  margin-left: 7px;
}
.step-content-output .output-button-div a{
  color: #fff;
}
.step-content img{
  width: 100%;
}
.custom-popup-div {
  position: absolute;
  /* top: 169px; */
  top: 22%;
  z-index: 999;
  /* margin: auto; */
  width: 400px;
  left: 45%;
}
span.create-pipeline-btn {
  background: #2d07c1;
  padding: 6px 15px;
  margin: 10px;
  display: inline-block;
  color: #fff;
  cursor: pointer;
  font-weight: 400;
  border-radius: 3px;
  font-size: 16px;
}
span.close-pop {
  position: absolute;
  background: #321fdb;
  right: -14px;
  /* padding: 0px 9px; */
  width: 33px;
  text-align: center;
  color: #fff;
  font-size: 35px;
  border-radius: 50%;
  top: -17px;
  height: 34px;
  line-height: 28px;
  cursor: pointer;
}
.view-file-button {
  cursor: pointer;
}
.view-hide-button {
  cursor: pointer;
  color: blue !important;
  text-decoration: underline !important;
}
.pipeline-list-item.active {
  background: #000 !important;
}
a.icons {
  padding-right: 0px 3px;
  padding-right: 2px;
}

.upload-div {
  min-height: 400px;
  display: block;
  text-align: center;
  padding-top: 10%;
}
.upload-div .progress-div{
  width: 83%;
  /* text-align: center; */
  margin: auto;
  margin-top: 33px;
}
span.file-name {
  position: absolute;
  left: 21%;
  font-size: 16px;
  font-weight: 400;
  text-transform: capitalize;
  padding: 7px 0;

}

.c-sidebar .c-sidebar-minimizer {
  background-color: "#152648"
}

.c-sidebar .c-sidebar-nav-link:hover, .c-sidebar .c-sidebar-nav-dropdown-toggle:hover {
    color: #fff;
    background: #152648;
}

.form-input-div {
  width: 48%;
  display: inline-block;
  margin: 0px 6px;
}
span.pipeline-card {
  font-weight: 700;
  font-size: large;
}
.progress-bar.progress-bar-animated.progress-bar-striped.bg-success {
  height: 16px;
}
button.start-process, button.start-process.disabled, button.start-process:disabled {
  background: #f79605;
  height: 50px;
  min-width: 174px;
  color: #fff;
  background-color: #f79605;
  /* border-color: #5769cd; */
  border: 2px solid #5769cd;
}
button.start-process:hover {
  background: #915906;
}
.MuiStepConnector-root.MuiStepConnector-horizontal.Mui-disabled:nth-child(5n) {
  display: none;
}
/* .MuiStep-horizontal {
  padding-left: 8px;
  padding-right: 8px;
  float: left;
  min-width: 100px;
  margin: 10px 0;
} */
/* .MuiStepConnector-root {
  -webkit-flex: 1 0 auto;
  flex: 1 0 auto;
  width: 63px;
  float: left;
  margin: 21px 0;
}
.MuiStepper-root {
  display: block !important;
} */
.files-table.table thead th {
  min-width: 150px;
}
/* .files-table-div {
  max-width: 938px;
  overflow-x: scroll;
}  */

button.resend-code-link {
  display: block;
  /* margin: auto; */
  margin-left: 25%;
  margin-bottom: 10px;
  background: #fff;
  border: 1px solid #000;
  border-radius: 3px;
}

.form-control-file.second {
  width: 84%;
  border-radius: 5px;
  margin-bottom: 15px;
}
button.upload-button.second.btn.btn-primary {
  border-radius: 5px;
}
ul.job-status-list {
  list-style: none;
  width: 100%;
  /* margin-left: 15%; */
}
.job-status-list li.job-status {
  float: left;
  margin-left: -1px;
  border: 1px solid gray;
  padding: 5px;
  font-weight: 700;
}

.job-status-list li.job-status.active.red{
  background-color: #f44336;
}
.job-status-list li.job-status.active.yellow{
  background-color: #ffc107;
}
.job-status-list li.job-status.active.green{
  background-color: #4caf50;
}
.output-files-block {
  clear: both;
}

a.btn.output-file-button {
  padding: 5px;
  background: #3c4b64;
  color: #fff;
  width: 133px;
  margin-top: 5px;
  margin-left: 5px;
}

a.btn.output-file-button:hover {
background: #1c2129;
}

.expendable-table {
  visibility: hidden;
  /* opacity: 0; */
  transition: height 0.5s ease-out;
  height: 0;
  padding-left: 27px;
}
/* .slide-hide {
 display: none;
 } */
 
.expendable-table.slide-show {
  visibility: visible;
  /* opacity: 1; */
  height: auto;
  transition: height 0.25s ease-in;
}
.files-table-div {
  border: 1px solid #d8dbe0;
}

.slide-hide {
  visibility: hidden;
  height: 0;
  transition: height 0.25s ease-in;
}
.slide-show {
 visibility: visible;
 /* max-height: 200px; */
 /* overflow-y:auto; */
 /* border: 1px solid #d8dbe0; */
}

.slide-show::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.slide-show {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
span.directory-name {
  cursor: pointer;
}

span.directory-name:hover {
  text-decoration: underline;
  color: #2d07c1;
}

.drop-zone-div, .inputs-main {
  display: inline-block;
  width: 50%;
  padding-left: 15%;
  border: 1px solid #d8dbe0;
  padding-top: 20px;
  padding-bottom: 20px;
  height: 242px;
  position: relative;
}

.uploaded-files-list {
  display: inline-block;
  position: relative;
  width: 50%;
  vertical-align: top;
  height: 242px;
  overflow-y: auto;
  border: 1px solid #d8dbe0;
}

.react-draggable, .image-dropzone  {
 border: 3px dotted rgb(153, 153, 153) !important;
 height: 200px;
  width: 200px;
}

.upload-progress {
  position: absolute;
}

.upload-button-div {
  width: 100%;
  text-align: center;
  /* height: 22px; */
  margin-top: 30px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.upload-button-div button {
    width: 100%;
    text-align: center;
    height: 28px;
    line-height: 15px;
    border-radius: 0;
}
.type-select-main {
  width: 25%;
}

.inputs-main {
  padding: 5px;
  position: relative;
}

button.upload-button.second.add-input-button.btn.btn-primary {
  position: absolute;
  top: 5px;
  height: 35px;
  right: 0;
}
.single-input-div {
  position: relative;
  padding-bottom: 5px;
}

.transaction-button-div {
    clear: both;
    width: 100%;
    text-align: center;
}
.nav-select-inputs>.form-group {
    display: inline-block;
    margin-right: 10px;
}
.nav-select-inputs {
  padding: 0px 34px;
  margin-bottom: -14px;
}
.nav-select-inputs label{
  font-weight: bold;
  margin-right: 5px;
}
.copy-button-div {
  position: absolute;
  bottom: 0px;
  width: 100%;
  clear: both;
  left: 0px;
}
.copy-button-div .copy-button {
  text-align: center;
  width: 100% !important;
  border-radius: 0px;
  height: 28px;
  line-height: 0px;
}

.badge.badge-green{
  background-color: green;
}
.badge.badge-yellow{
  background-color: rgb(241 148 8 / 95%);
}
.badge.badge-gray{
  background-color: rgb(48, 47, 47);
}

button.upload-button.second.transaction-create-button.btn.btn-primary {
  margin-top: 10%;
}
.pipeline-parameters-main{
  border: 1px solid #d8dbe0;
  padding: 5px;
}
.pipeline-parameters-main h4 {
  text-align: center;
  padding: 10px 0px;
}

ul.parameter-heading-list {
  list-style: none;
  padding: 0px;
  margin-left: -1px;
}

ul.parameter-heading-list li {
  float: left;
  padding: 10px;
  font-weight: 700;
  border: 1px solid #d8dbe0;
}
/* 
ul.parameter-heading-list li:nth-child(1) {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
ul.parameter-heading-list li:nth-last-child(1) {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
} */
/* ul.parameter-heading-list li:nth-child(-n + 2){
  min-width: 15%;
}
ul.parameter-heading-list li:nth-last-child(2){
  min-width: 55%;
}
ul.parameter-heading-list li:nth-last-child(1){
  min-width: 15%;
}
.pipeline-parameters-main .input-group-text {
    width: 15%;
    overflow-x: auto;
}

.pipeline-parameters-main .form-control:nth-last-child(2) {
    min-width: 55%;
}
.pipeline-parameters-main .form-control:nth-last-child(1) {
  min-width: 15%;
} */
.finish-button {
  padding: 10px 0px;
  text-align: center;
}
.finish-button .upload-finish-buttton {
  width: 200px;
}

.info-cicon {
  color: #000;
}
.icon-link{
  margin-left: 5px;
}
.parameter-name {
  width: 20%;
  display: flex;
}
.parameter-main {
  width: 80%;
  display: inline-block;
}
.multi-pair-parameter-main.parameter-main .multi-pair-parameter-div {
  display: flex;
  width: 100%;
}
.multi-pair-parameter-main.parameter-main .multi-pair-parameter-div div {
  min-width:20%;
  display: inline-block;
}
.multi-pair-parameter-main.parameter-main .multi-pair-parameter-div input {
  max-width: 80%;
  display: inline-block;
}
.parameter-flax {
  display: flex;
  flex-direction: inherit;
}
.parameter-name .input-group-text {
  width: 100%;
}
.input-group-text {
  border-radius: 0;
}
.parameter-flax input {
  border-radius: 0;
}
.multi-pair-parameter-main.parameter-main .multi-pair-parameter-div .input-group-text {
  background: #fff;
}

.c-switch-lg {
   margin-top: 3px;
   margin-left: 12px;
}
.multi-pair-parameter-main.parameter-main {
  position: relative;
}
.file-progress-bar {
  position: absolute;
  right: 13px;
  width: 300px;
  top: 11px;
}
.pipelines-parameter-upload-progress-main {
  width: 100%;
  position: relative;
}

.pipelines-parameter-upload-progress {
  position: absolute;
  z-index: 9999;
  left: 27%;
  top: 21%;
}
.pipelines-parameter-upload-progress-main {
  width: 29%;
  margin-left: 34%;
  background: #3c4b64;
  min-height: 41%;
  position: relative;
  position: absolute;
  border-radius: 32px;
  z-index: 999;
  box-shadow: 1px 1px 33px 13px rgba(0,0,0,0.75);
  -webkit-box-shadow: 1px 1px 33px 13px rgba(0,0,0,0.75);
  -moz-box-shadow: 1px 1px 33px 13px rgba(0,0,0,0.75);
}

.upload-error-div {
  background: #fff;
  padding: 20px;
  border-radius: 18px;
  position: absolute;
  top: 25px;
  /* left: 0px; */
  border: 1px solid red;
  /* font-size: 10px; */
}

p.upload-error {
  color: red;
  font-weight: bold;
  text-align: center;
}

.ejhWCc {
  min-width: 200px;
  max-width: 200px;
  height: 201px;

}

.preview-container {
  position: relative;
}

.upload-error-div-s3 {
  position: absolute;
  background: #fff;
  padding: 19px;
  top: 60px;
  border: 1px solid red;
  border-radius: 13px;
  z-index: 999;
}

.image-dropzone.drag {
  border: 2px solid green !important;
}
.pipeline-nav {
  background: #dcdfe5;
}

a.nav-link.active {
  background: gray;
  color: #fff;
}
.form-group.chat-form {
  position: relative;
}

button.chat-button.btn.btn-outline-primary.btn-sm.btn-square {
  position: absolute;
  top: 0px;
  height: 37px;
  right: 0px;
  width: 99px;
}
.chat-body {
  height: 430px;
  overflow-y: auto;
}

.chat-message {
  background: #ebe7e7;
  padding: 10px;
  margin-bottom: 8px;
  border-radius: 10px;
  width: fit-content;
  max-width: 70%;
}

.question-message.chat-message {
  float: right;
  background: #3c4b64;
  color: #fff;
}

.chat-message-main {
  display: inline-block;
  width: 100%;
}

.terms-and-conditions-overview {
  position: absolute;
  width: 100%;
  left: 0;
  z-index: 99999;
  top: 0;
  height: 100vh;
  background: #d3c9c9;
  /* opacity: 0.8; */
  padding: 60px;
}

.c-body {
  position: relative;
}
.terms-popup-close-button{
  float: right;
}

.terms-conditions-pre{
  display: inline;
  margin: 0;
  white-space: pre-wrap;
}

.terms-and-conditions-popup{
  -webkit-box-shadow: 0px 2px 34px 4px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 2px 34px 4px rgba(0,0,0,0.75);
  box-shadow: 0px 2px 34px 4px rgba(0,0,0,0.75);
}

.terms-and-conditions-popup .card-body{
  height: 65vh;
  overflow-y: auto;
  padding-bottom: 30px;
}